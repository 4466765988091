.modal {
  transition: all 0.4s ease-out !important;

  :global(.modal-footer.inside-tab) {
    align-self: flex-start;
    position: sticky;
    bottom: 0;
    width: calc(100% + var(--bs-modal-header-padding-x) + var(--bs-modal-header-padding-x));
    margin-left: calc(var(--bs-modal-header-padding-x) * -1);
    border: 0 !important;
    z-index: var(--bs-modal-zindex);
  }

  :global(.tab-content) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    :global(.stretched-container .inside-tab) {
      width: calc(100% + var(--bs-modal-header-padding-x));
    }
  }

  :global(.modal-header) {
    border: 0;
  }

  // :global(.modal-footer) {
  //   margin-top: 1rem;
  // }

  @media (min-width: 576px) and (max-width: 899px) {
    &:global(.modal-lg) {
      max-width: 95% !important;
    }
  }

  @media (max-width: 576px) {
    // &:global(.modal-lg) {
    //   width: 95% !important;
    //   max-width: 95% !important;
    // }
  }
}

.content {
  border-radius: var(--bs-border-radius-xl) !important;
  border: 0 !important;
  min-height: 300px;
  max-height: calc(100% - 150px) !important;
  // height: 60%;
  // height: 540px;
  // max-height: 540px;

  &:global(.sm) {
    // height: auto;
    min-height: auto;
  }

  &:global(.sm) {
    // height: auto;
    min-height: auto;
  }

  &:global(.login) {
    height: 100%;
    max-height: 600px;
  }

  &:global(.h-360) {
    height: 360px;
    max-height: 360px;
  }

  &:global(.h-540) {
    height: 540px;
    max-height: 540px;
  }

  &:global(.h-600) {
    height: 600px;
    max-height: 600px;
  }

  &:global(.h-720) {
    height: 720px;
    max-height: 720px;
  }
}

.navDropdown {
  :global(.dropdown-item.active) {
    background-color: var(--bs-gray-600);
  }

  :global(.dropdown-item:active) {
    color: var(--bs-gray-600);
    background-color: var(--bs-gray-200);
  }

  :global(.dropdown-item.active) {
    background-color: var(--bs-gray-600);
  }
}

.loginLogo {
  width: 100% !important;
  height: unset;
  object-fit: contain;
  max-width: 648px;
}
