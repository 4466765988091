.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
}

.site {
  :global(span.toggle) {
    display: none;
  }

  &:hover {
    :global(span.toggle) {
      display: block;
      position: absolute;
      right: 0;
      background: #fff;
      padding: 5px;
      border-radius: 50%;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
