.toasts {
  z-index: 99999999;

  :global(.toast) {
    font-size: 1.2rem;
    z-index: 99999999;
    position: fixed;
    bottom: 10px;
    right: 10px;

    &:global(.bg-success) {
      color: var(--bs-white);
    }
  }
}
