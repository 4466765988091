:root {
  --rs-text-disabled: var(--bs-gray-300);
}

.rs-picker {
  width: 100%;
}

.rs-picker-popup {
  z-index: calc(var(--zIndex-globalLoader) - 1);
}

.rs-input-group > .rs-input,
.rs-input-group > .rs-input-group-addon {
  height: 31px;
}

.rs-input-group-lg.rs-input-group > .rs-input,
.rs-input-group-lg.rs-input-group > .rs-input-group-addon {
  height: 43px;
  padding-bottom: 0;
}

.rs-date-range-input.rs-input {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.rs-input {
  color: var(--bs-gray-600);
  font-size: var(--bs-body-fs) !important;
}

.rs-input::placeholder {
  color: var(--bs-gray-500);
  font-weight: lighter;
  font-style: italic;
}

.rs-picker.d-addon-none .rs-input-group-addon {
  display: none;
}

.rs-picker.is-invalid .rs-input-group {
  border: 1px solid var(--bs-form-invalid-border-color);
}

.rs-picker-daterange-predefined {
  height: 100%;
}

.rs-picker-daterange-panel > .rs-stack > .rs-stack-item:first-child {
  align-self: stretch;
}

.input-group .rs-input-group,
.input-group .rs-date-range-input.rs-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-floating .rs-picker-date ~ label,
.form-floating .rs-picker-daterange ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

//Calendar on Payroll
.rs-calendar * {
  user-select: none;
}

.rs-calendar .rs-calendar-btn-today {
  display: none;
}

.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: unset;
}

.rs-calendar-panel .rs-calendar-table-cell-day {
  margin-top: 0;
}

.rs-calendar-table-cell.rs-calendar-table-cell-is-today {
  background-color: var(--bs-secondary);

  span.rs-calendar-table-cell-day {
    background-color: transparent;
  }
}

.rs-calendar-table-cell.bg-primary .rs-calendar-table-cell-content:hover {
  background-color: var(--bs-primary);
}

.rs-calendar-table-cell.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell.bg-secondary .rs-calendar-table-cell-content:hover {
  background-color: var(--bs-secondary);
}

.rs-calendar {
  .rs-calendar-btn-close {
    bottom: 0;
  }

  .rs-calendar-month-dropdown {
    margin-left: unset;
    margin-right: unset;
    width: 100%;
  }

  .rs-calendar-month-dropdown-row {
    display: flex;
    align-items: center;
  }

  .rs-calendar-month-dropdown-list {
    display: grid;
    gap: 0.25rem;
    grid-template-columns: repeat(6, 20px);

    &:before,
    &:after {
      content: unset;
    }

    .rs-calendar-month-dropdown-cell {
      margin: 0;
      height: 20px;
      width: 20px;

      .rs-calendar-month-dropdown-cell-content {
        width: inherit;
        height: inherit;
        padding: 0;
      }
    }
  }
}
